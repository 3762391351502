<template>
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
          <el-form-item label="省份">
            <el-select style="width: 120px;" v-model="filters.provinceCode" placeholder="请选择省" filterable clearable>
              <el-option
                  v-for="item in provinceArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select style="width: 120px;" v-model="filters.applyStatus" placeholder="申请状态" filterable
                       clearable>
              <el-option
                  v-for="item in applyStatusArr"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="">
            <el-date-picker @change="changePicker" v-model="pickerData" type="daterange" range-separator="至"
                            start-placeholder="开始" value-format="yyyy-MM-dd" end-placeholder="结束"
                            style="width: 200px;">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="">
            <el-input style="width: 240px;" v-model="filters.invoiceQuery" @input="(e) => (filters.invoiceQuery = utils.validForbid(e))"
                      clearable placeholder="购买方名称/所属区县/合同编号"></el-input>
          </el-form-item>
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!--  列表-->
    <el-table
        :data="objectDataList"
        border
        style="width: 100%"
        v-loading="listLoading"
        ref="multipleTable"
        @current-change='currentChange'
        :highlight-current-row='true'
        :cell-style="rowClass"
    >
      <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
      <el-table-column prop="contractNo" label="合同编号" width='100' align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="areaData" label="所属区县" width='110' align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="receivableAmount" label="应收款金额" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="invoiceMoney" label="实际申请金额" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="invoiceTitle" label="购买方名称" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="invoiceFax" label="购方税号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="externalPersonPhone" label="手机号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="externalPersonEmail" label="邮箱" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="applyStatus" label="审核状态" align="center" show-overflow-tooltip>
        <template slot-scope='scope'>
          <el-tag v-if="scope.row.applyStatus === 0" type="success">待审核</el-tag>
          <el-tag v-if="scope.row.applyStatus === 1" type="danger">审核失败</el-tag>
          <el-tag v-if="scope.row.applyStatus === 2" >审核成功</el-tag>
          <el-tag v-if="scope.row.applyStatus === 9" type="info">待提交</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="" label="项目详情" align="center" show-overflow-tooltip>
                <template #default="scope">
                  <el-button @click="invoiceDetails(scope.row)" type="text">{{'查看明细'}}</el-button>
                </template>
      </el-table-column>
      <el-table-column prop="createTime" label="申请日期" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="invoiceStatus" label="开票状态" align="center" show-overflow-tooltip>
        <template slot-scope='scope'>
          <el-tag v-if="scope.row.invoiceStatus === 0" type="success">未开票</el-tag>
          <el-tag v-if="scope.row.invoiceStatus === 1" type="danger">开票失败</el-tag>
          <el-tag v-if="scope.row.invoiceStatus === 2" >已开票</el-tag>
          <el-tag v-if="scope.row.invoiceStatus === 3" type="info">已作废</el-tag>
          <el-tag v-if="scope.row.invoiceStatus === 9" >开票中</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.pageIndex"
          :page-sizes="pages.pageArr"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataCount">
      </el-pagination>
    </el-col>
    <el-dialog title="发票详情" :visible.sync="invoiceDialog" v-model="invoiceDialog"
               :close-on-click-modal="false" width='80%'>
      <el-form label-position="right" :inline="true" :model="noteForm"  ref="noteRef" label-width="100px" style="width: 100%;">

        <el-card style="margin-bottom:5px">
          <div>
            <el-form-item label="地区:" style="margin-right: 30px;">
              <span style="display: inline-block; width: 180px;">{{ this.needData?this.needData.areaData:'' }}</span>
            </el-form-item>
            <el-form-item label="合同编号:" style="margin-right: 35px;">
              <span style="display: inline-block; width: 180px;">{{ this.needData?this.needData.contractNo:'' }}</span>
            </el-form-item>
            <!--            <el-form-item label="开票金额合计:" >-->
            <!--              <span style="display: inline-block; width: 180px;">{{ this.totalNums }}</span>-->
            <!--            </el-form-item>-->
          </div>
          <div>
            <el-form-item  label="开票抬头:" prop="invoiceTitle">
              <el-input v-model="noteForm.invoiceTitle" readonly></el-input>
            </el-form-item>
            <el-form-item  label="购方税号:" prop="invoiceFax">
              <el-input  v-model="noteForm.invoiceFax" readonly></el-input>
            </el-form-item>

            <el-form-item  label="手机号:" prop="phone">
              <el-input v-model="noteForm.phone" readonly></el-input>
            </el-form-item>
            <el-form-item  label="邮箱:" prop="email">
              <el-input v-model="noteForm.email" readonly></el-input>
            </el-form-item>
          </div>
        </el-card>
        <template>

          <el-card style="margin-bottom:5px" v-for="(list, index) in noteForm.contentList" :key="index">

            <div style="margin-bottom: 15px;">
              <el-form-item label="项目名称:">
                <el-select v-model="noteForm.contentList[index].drProjectId" style="width: 400px" clearable filterable remote
                           filterable  @change="changeSelect(index, $event)"  disabled>
                  <el-option v-for="item in companyData" :key="item.id" :label="item.projectName"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item  label="建设单位:"
                             :prop="'contentList.' + index + '.constructionUnit'">
                <el-input @change="changeContent($event, index)" style="width: 200px;"
                          v-model="noteForm.contentList[index].constructionUnit" readonly></el-input>
              </el-form-item>
              <!--              <el-button   size="medium" type="danger" disabled-->
              <!--                           @click="delContentInput(index)" style="float: right;">删除项目-->
              <!--              </el-button>-->
            </div>

            <div >
              <el-form-item label="提供服务分类:">
              </el-form-item>
            </div>
            <div style="margin-left: 30px;" v-if="noteForm.contentList[index].unitPrice">
              <el-form-item label="图纸审查:" >
              </el-form-item>
              <el-form-item label="单价（元/㎡）:"
                            :prop="'contentList.' + index + '.unitPrice'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;" readonly
                          v-model="noteForm.contentList[index].unitPrice"></el-input>
              </el-form-item>
              <el-form-item label="数量（㎡）:"
                            :prop="'contentList.' + index + '.nums'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums"></el-input>
              </el-form-item>
              <el-form-item label="合计（元）:"
                            :prop="'contentList.' + index + '.total'">
                <el-input  style="width: 120px;" readonly
                           v-model="noteForm.contentList[index].nums*noteForm.contentList[index].unitPrice"></el-input>
              </el-form-item>
              <el-form-item  label="中标费率:"
                             :prop="'contentList.' + index + '.discountRate'">
                <el-input @change="changeContent($event, index)" style="width: 80px;" readonly
                          v-model="noteForm.contentList[index].discountRate"></el-input>
              </el-form-item>
              <el-form-item  label="开票金额:"
                             :prop="'contentList.' + index + '.invoiceAmount'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums*noteForm.contentList[index].unitPrice*noteForm.contentList[index].discountRate*0.01"></el-input>
              </el-form-item>
            </div>

            <div style="margin-left: 30px;" v-if="noteForm.contentList[index].unitPrice1">
              <el-form-item label="技术服务:" >
              </el-form-item>
              <el-form-item label="单价（元/㎡）:"
                            :prop="'contentList.' + index + '.unitPrice1'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;" readonly
                          v-model="noteForm.contentList[index].unitPrice1"></el-input>
              </el-form-item>
              <el-form-item  label="数量（个）:"
                             :prop="'contentList.' + index + '.nums1'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums1"></el-input>
              </el-form-item>
              <el-form-item  label="合计（元）:"
                             :prop="'contentList.' + index + '.total1'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums1*noteForm.contentList[index].unitPrice1"></el-input>
              </el-form-item>
              <!--              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="中标费率:"-->
              <!--                            :prop="'contentList.' + index + '.content'">-->
              <!--                <el-input @change="changeContent($event, index)" style="width: 80px;"-->
              <!--                          v-model="noteForm.contentList[index].bn"></el-input>-->
              <!--              </el-form-item>-->
              <el-form-item  label="开票金额:"
                             :prop="'contentList.' + index + '.invoiceAmount1'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums1*noteForm.contentList[index].unitPrice1"></el-input>
              </el-form-item>
            </div>
            <div style="margin-left: 30px;" v-if="noteForm.contentList[index].unitPrice2">
              <el-form-item label="消防检查:" >
              </el-form-item>
              <el-form-item  label="单价（元/㎡）:"
                             :prop="'contentList.' + index + '.unitPrice2'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;" readonly
                          v-model="noteForm.contentList[index].unitPrice2"></el-input>
              </el-form-item>
              <el-form-item  label="数量（个）:"
                             :prop="'contentList.' + index + '.nums2'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums2"></el-input>
              </el-form-item>
              <el-form-item  label="合计（元）:"
                             :prop="'contentList.' + index + '.total2'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums2*noteForm.contentList[index].unitPrice2"></el-input>
              </el-form-item>
              <!--              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="中标费率:"-->
              <!--                            :prop="'contentList.' + index + '.content'">-->
              <!--                <el-input @change="changeContent($event, index)" style="width: 80px;"-->
              <!--                          v-model="noteForm.contentList[index].bn"></el-input>-->
              <!--              </el-form-item>-->
              <el-form-item  label="开票金额:"
                             :prop="'contentList.' + index + '.invoiceAmount2'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums2*noteForm.contentList[index].unitPrice2"></el-input>
              </el-form-item>
            </div>
            <div style="margin-left: 30px;" v-if="noteForm.contentList[index].unitPrice3">
              <el-form-item label="过程监管:">
              </el-form-item>
              <el-form-item label="单价（元/㎡）:"
                            :prop="'contentList.' + index + '.unitPrice3'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;" readonly
                          v-model="noteForm.contentList[index].unitPrice3"></el-input>
              </el-form-item>
              <el-form-item  label="数量（个）:"
                             :prop="'contentList.' + index + '.nums3'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums3"></el-input>
              </el-form-item>
              <el-form-item  label="合计（元）:"
                             :prop="'contentList.' + index + '.total3'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums3*noteForm.contentList[index].unitPrice3"></el-input>
              </el-form-item>
              <!--              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="中标费率:"-->
              <!--                            :prop="'contentList.' + index + '.content'">-->
              <!--                <el-input @change="changeContent($event, index)" style="width: 80px;"-->
              <!--                          v-model="noteForm.contentList[index].bn"></el-input>-->
              <!--              </el-form-item>-->
              <el-form-item  label="开票金额:"
                             :prop="'contentList.' + index + '.invoiceAmount3'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums3*noteForm.contentList[index].unitPrice3"></el-input>
              </el-form-item>
            </div>
            <div v-if="noteForm.contentList[index].unitPrice4">
              <el-form-item label="评估报告监管服务:" label-width="140">
              </el-form-item>
              <el-form-item  label="单价（元/㎡）:"
                             :prop="'contentList.' + index + '.unitPrice4'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;" readonly
                          v-model="noteForm.contentList[index].unitPrice4"></el-input>
              </el-form-item>
              <el-form-item  label="数量（个）:"
                             :prop="'contentList.' + index + '.nums4'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums4"></el-input>
              </el-form-item>
              <el-form-item label="合计（元）:"
                            :prop="'contentList.' + index + '.total4'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums4*noteForm.contentList[index].unitPrice4"></el-input>
              </el-form-item>
              <!--              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="中标费率:"-->
              <!--                            :prop="'contentList.' + index + '.content'">-->
              <!--                <el-input @change="changeContent($event, index)" style="width: 80px;"-->
              <!--                          v-model="noteForm.contentList[index].bn"></el-input>-->
              <!--              </el-form-item>-->
              <el-form-item  label="开票金额:"
                             :prop="'contentList.' + index + '.invoiceAmount4'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums4*noteForm.contentList[index].unitPrice4"></el-input>
              </el-form-item>

            </div>
          </el-card>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="invoiceDialog= false">关闭</el-button>
      </div>
    </el-dialog>
<!--  开票申请，编辑  -->
    <el-dialog v-if="openInvoiceDialog" :title="editDialog?'编辑':'申请开票'" :visible.sync="openInvoiceDialog" v-model="openInvoiceDialog"
               :close-on-click-modal="false" width='70%'>
      <el-form label-position="right" :inline="true" :model="noteForm" :rules="noteRules" ref="noteRef" label-width="100px" style="width: 100%;">
        <el-form-item >
          <!-- 内部+ -->

        </el-form-item>
        <el-card style="margin-bottom:5px">
          <div>
            <el-form-item label="地区:" style="margin-right: 30px;">
              <el-select v-model="noteForm.changArea" style="width: 200px" clearable filterable remote
                         filterable  @change="changeSelectArea">
                <el-option v-for="item in companyListData" :key="item.id" :label="item.customerName"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="合同编号:" label-width="83px" style="margin-right: 32px;">
              <span style="display: inline-block; width: 180px;">{{ this.contractNo?this.contractNo:undefined }}</span>
            </el-form-item>

          </div>
          <div>
            <el-form-item :rules="{ required: true, message: '不能为空', trigger: 'blur' }" label="开票抬头:"
                          prop="invoiceTitle">
              <el-input v-model="noteForm.invoiceTitle"></el-input>
            </el-form-item>
            <el-form-item :rules="{ required: true, message: '不能为空', trigger: 'blur' }" label="购方税号:"
                          prop="invoiceFax">
              <el-input  v-model="noteForm.invoiceFax"></el-input>
            </el-form-item>

            <el-form-item :rules="{ required: true, message: '不能为空', trigger: 'blur' }" label="手机号:"
                          prop="phone">
              <el-input v-model="noteForm.phone"></el-input>
            </el-form-item>
            <el-form-item :rules="{ required: true, message: '不能为空', trigger: 'blur' }" label="邮箱:"
                          prop="email">
              <el-input v-model="noteForm.email"></el-input>
            </el-form-item>
          </div>
        </el-card>
        <template>

          <el-card style="margin-bottom:5px" v-for="(list, index) in noteForm.contentList" :key="index" >

            <div style="margin-bottom: 15px;">
              <el-form-item label="项目名称:">
                <el-select v-model="noteForm.contentList[index].drProjectId" style="width: 200px" clearable filterable remote
                           filterable  @change="changeSelect(index, $event)">
                  <el-option v-for="item in companyData" :key="item.id" :label="item.projectName"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item  label="建设单位:"
                            :prop="'contentList.' + index + '.constructionUnit'">
                <el-input @change="changeContent($event, index)" style="width: 200px;" readonly
                          v-model="noteForm.contentList[index].constructionUnit"></el-input>
              </el-form-item>
<!--              <el-form-item label="开票金额合计:">-->
<!--                              <span style="display: inline-block; width: 180px;">{{-->
<!--                                  (noteForm.contentList[index].nums * noteForm.contentList[index].unitPrice * noteForm.contentList[index].discountRate||0 )+-->
<!--                                  (noteForm.contentList[index].nums1 * noteForm.contentList[index].unitPrice1||0) +-->
<!--                                  (noteForm.contentList[index].nums2 * noteForm.contentList[index].unitPrice2||0) +-->
<!--                                  (noteForm.contentList[index].nums3 * noteForm.contentList[index].unitPrice3||0)+-->
<!--                                  (noteForm.contentList[index].nums4 * noteForm.contentList[index].unitPrice4||0)-->
<!--                                }}</span>-->
<!--                            </el-form-item>-->
              <el-button   size="medium" type="danger" :disabled="noteForm.contentList.length === 1"
                           @click="delContentInput(index)" style="float: right;">删除项目
              </el-button>
            </div>

            <div >
              <el-form-item label="提供服务分类:">
              </el-form-item>
              <el-form-item >
                <template #default>
                  <el-checkbox-group
                      v-model="noteForm.contentList[index].checkService"
                      :min="1"
                      :max="5">
                    <el-checkbox v-for="city in cities" :label="city.value" :key="city.id" >
                      {{city.value}}
                    </el-checkbox>
                  </el-checkbox-group>
                </template>
              </el-form-item>
            </div>
            <div v-if="isChecked('图纸审查',index)" style="margin-left: 30px;">
              <el-form-item label="图纸审查:">
              </el-form-item>
              <el-form-item  label="单价（元/㎡）:" :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }"
                            :prop="'contentList.' + index + '.unitPrice'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;"
                          v-model="noteForm.contentList[index].unitPrice"></el-input>
              </el-form-item>
              <el-form-item  label="数量（㎡）:" :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }"
                            :prop="'contentList.' + index + '.nums'">
                <el-input @change="changeContent($event, index)" style="width: 120px;"
                          v-model="noteForm.contentList[index].nums"></el-input>
              </el-form-item>
              <el-form-item label="合计（元）:"
                            :prop="'contentList.' + index + '.total'">
                <el-input  style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums*noteForm.contentList[index].unitPrice"></el-input>
              </el-form-item>
              <el-form-item :rules="{ required: true, message: '不能为空', trigger: 'blur' }" label="中标费率:"
                            :prop="'contentList.' + index + '.discountRate'">
                <el-input @change="changeContent($event, index)" style="width: 80px;"
                          v-model="noteForm.contentList[index].discountRate"></el-input>
              </el-form-item>
              <el-form-item label="开票金额:"
                            :prop="'contentList.' + index + '.invoiceAmount'">
                <el-input @change="changeContent($event, index)" style="width: 120px;"
                          v-model="0.01*noteForm.contentList[index].nums*noteForm.contentList[index].unitPrice*noteForm.contentList[index].discountRate"></el-input>
              </el-form-item>
            </div>

            <div v-if="isChecked('技术服务',index)" style="margin-left: 30px;">
              <el-form-item label="技术服务:">
              </el-form-item>
              <el-form-item  label="单价（元/㎡）:" :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }"
                            :prop="'contentList.' + index + '.unitPrice1'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;"
                          v-model="noteForm.contentList[index].unitPrice1"></el-input>
              </el-form-item>
              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="数量（个）:"
                            :prop="'contentList.' + index + '.nums1'">
                <el-input @change="changeContent($event, index)" style="width: 120px;"
                          v-model="noteForm.contentList[index].nums1"></el-input>
              </el-form-item>
              <el-form-item  label="合计（元）:"
                            :prop="'contentList.' + index + '.total1'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums1*noteForm.contentList[index].unitPrice1"></el-input>
              </el-form-item>
<!--              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="中标费率:"-->
<!--                            :prop="'contentList.' + index + '.content'">-->
<!--                <el-input @change="changeContent($event, index)" style="width: 80px;"-->
<!--                          v-model="noteForm.contentList[index].bn"></el-input>-->
<!--              </el-form-item>-->
              <el-form-item  label="开票金额:"
                            :prop="'contentList.' + index + '.invoiceAmount1'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums1*noteForm.contentList[index].unitPrice1*noteForm.contentList[index].discountRate1*0.01"></el-input>
              </el-form-item>
            </div>
            <div v-if="isChecked('消防检查',index)" style="margin-left: 30px;">
              <el-form-item label="消防检查:">
              </el-form-item>
              <el-form-item  label="单价（元/㎡）:" :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }"
                            :prop="'contentList.' + index + '.unitPrice2'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;"
                          v-model="noteForm.contentList[index].unitPrice2"></el-input>
              </el-form-item>
              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="数量（个）:"
                            :prop="'contentList.' + index + '.nums2'">
                <el-input @change="changeContent($event, index)" style="width: 120px;"
                          v-model="noteForm.contentList[index].nums2"></el-input>
              </el-form-item>
              <el-form-item  label="合计（元）:"
                            :prop="'contentList.' + index + '.total2'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums2*noteForm.contentList[index].unitPrice2"></el-input>
              </el-form-item>
<!--              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="中标费率:"-->
<!--                            :prop="'contentList.' + index + '.content'">-->
<!--                <el-input @change="changeContent($event, index)" style="width: 80px;"-->
<!--                          v-model="noteForm.contentList[index].bn"></el-input>-->
<!--              </el-form-item>-->
              <el-form-item  label="开票金额:"
                            :prop="'contentList.' + index + '.invoiceAmount2'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums2*noteForm.contentList[index].unitPrice2*noteForm.contentList[index].discountRate2*0.01"></el-input>
              </el-form-item>
            </div>
            <div v-if="isChecked('过程监管',index)" style="margin-left: 30px;">
              <el-form-item label="过程监管:">
              </el-form-item>
              <el-form-item  label="单价（元/㎡）:" :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }"
                            :prop="'contentList.' + index + '.unitPrice3'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;"
                          v-model="noteForm.contentList[index].unitPrice3"></el-input>
              </el-form-item>
              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="数量（个）:"
                            :prop="'contentList.' + index + '.nums3'">
                <el-input @change="changeContent($event, index)" style="width: 120px;"
                          v-model="noteForm.contentList[index].nums3"></el-input>
              </el-form-item>
              <el-form-item  label="合计（元）:"
                            :prop="'contentList.' + index + '.total3'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums3*noteForm.contentList[index].unitPrice3"></el-input>
              </el-form-item>
<!--              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="中标费率:"-->
<!--                            :prop="'contentList.' + index + '.content'">-->
<!--                <el-input @change="changeContent($event, index)" style="width: 80px;"-->
<!--                          v-model="noteForm.contentList[index].bn"></el-input>-->
<!--              </el-form-item>-->
              <el-form-item  label="开票金额:"
                            :prop="'contentList.' + index + '.invoiceAmount3'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums3*noteForm.contentList[index].unitPrice3*noteForm.contentList[index].discountRate3*0.01"></el-input>
              </el-form-item>
            </div>
            <div v-if="isChecked('评估报告监管服务',index)">
              <el-form-item label="评估报告监管服务:" label-width="140">
              </el-form-item>
              <el-form-item  label="单价（元/㎡）:" :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }"
                            :prop="'contentList.' + index + '.unitPrice4'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;"
                          v-model="noteForm.contentList[index].unitPrice4"></el-input>
              </el-form-item>
              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="数量（个）:"
                            :prop="'contentList.' + index + '.nums4'">
                <el-input @change="changeContent($event, index)" style="width: 120px;"
                          v-model="noteForm.contentList[index].nums4"></el-input>
              </el-form-item>
              <el-form-item label="合计（元）:"
                            :prop="'contentList.' + index + '.total4'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums4*noteForm.contentList[index].unitPrice4"></el-input>
              </el-form-item>
<!--              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="中标费率:"-->
<!--                            :prop="'contentList.' + index + '.content'">-->
<!--                <el-input @change="changeContent($event, index)" style="width: 80px;"-->
<!--                          v-model="noteForm.contentList[index].bn"></el-input>-->
<!--              </el-form-item>-->
              <el-form-item  label="开票金额:"
                            :prop="'contentList.' + index + '.invoiceAmount4'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums4*noteForm.contentList[index].unitPrice4*noteForm.contentList[index].discountRate4*0.01"></el-input>
              </el-form-item>
            </div>
          </el-card>
        </template>
        <div style="text-align: center;">
        <el-form-item label="开票金额合计:" style="text-align: center;">

            <el-button type="primary" size="small" @click="getInvoiceMoneyAll">
              {{'一键汇总'}}
            </el-button>
            {{this.invoiceMoneyAll}}

        </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="!editDialog" type="success" @click="addContentInput">添加项目</el-button>
        <el-button v-if="editDialog" type="success" @click="addContentEdit">添加项目</el-button>
        <el-button v-if="!editDialog" type="primary" @click="saveNote" :loading="saveShowLoading">保存</el-button>
        <el-button v-if="editDialog" type="primary" @click="saveEdit" :loading="saveShowLoading">保存</el-button>
        <el-button @click.native="addNoteDiaClose">取消</el-button>
      </div>
    </el-dialog>
<!--  审核  -->
    <el-dialog title="审核" :visible.sync="openReviewDialog" v-model="openReviewDialog"
               :close-on-click-modal="false" width='70%'>
      <el-form label-position="right" :inline="true" :model="noteForm"  ref="noteRef" label-width="100px" style="width: 100%;">

        <el-card style="margin-bottom:5px">
          <div>
            <el-form-item label="地区:" style="margin-right: 30px;">
              <span style="display: inline-block; width: 180px;">{{ this.needData?this.needData.areaData:'' }}</span>
            </el-form-item>
            <el-form-item label="合同编号:" style="margin-right: 35px;">
              <span style="display: inline-block; width: 180px;">{{ this.needData?this.needData.contractNo:'' }}</span>
            </el-form-item>
<!--            <el-form-item label="开票金额合计:" >-->
<!--              <span style="display: inline-block; width: 180px;">{{ this.totalNums }}</span>-->
<!--            </el-form-item>-->
          </div>
          <div>
            <el-form-item  label="开票抬头:" prop="invoiceTitle">
              <el-input v-model="noteForm.invoiceTitle" readonly></el-input>
            </el-form-item>
            <el-form-item  label="购方税号:" prop="invoiceFax">
              <el-input  v-model="noteForm.invoiceFax" readonly></el-input>
            </el-form-item>

            <el-form-item  label="手机号:" prop="phone">
              <el-input v-model="noteForm.phone" readonly></el-input>
            </el-form-item>
            <el-form-item  label="邮箱:" prop="email">
              <el-input v-model="noteForm.email" readonly></el-input>
            </el-form-item>
          </div>
        </el-card>
        <template>

          <el-card style="margin-bottom:5px" v-for="(list, index) in noteForm.contentList" :key="index">

            <div style="margin-bottom: 15px;">
              <el-form-item label="项目名称:">
                <el-select v-model="noteForm.contentList[index].drProjectId" style="width: 400px" clearable filterable remote
                           filterable  @change="changeSelect(index, $event)"  disabled>
                  <el-option v-for="item in (sameCompanyId?companyDataOther:companyData)" :key="item.id" :label="item.projectName"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item  label="建设单位:"
                            :prop="'contentList.' + index + '.constructionUnit'">
                <el-input @change="changeContent($event, index)" style="width: 200px;"
                          v-model="noteForm.contentList[index].constructionUnit" readonly></el-input>
              </el-form-item>
              <el-button   size="medium" type="danger" disabled
                           @click="delContentInput(index)" style="float: right;">删除项目
              </el-button>
            </div>

            <div >
              <el-form-item label="提供服务分类:">
              </el-form-item>
            </div>
            <div style="margin-left: 30px;" v-if="noteForm.contentList[index].unitPrice">
              <el-form-item label="图纸审查:" >
              </el-form-item>
              <el-form-item label="单价（元/㎡）:"
                            :prop="'contentList.' + index + '.unitPrice'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;" readonly
                          v-model="noteForm.contentList[index].unitPrice"></el-input>
              </el-form-item>
              <el-form-item label="数量（㎡）:"
                            :prop="'contentList.' + index + '.nums'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums"></el-input>
              </el-form-item>
              <el-form-item label="合计（元）:"
                            :prop="'contentList.' + index + '.total'">
                <el-input  style="width: 120px;" readonly
                           v-model="noteForm.contentList[index].nums*noteForm.contentList[index].unitPrice"></el-input>
              </el-form-item>
              <el-form-item  label="中标费率:"
                            :prop="'contentList.' + index + '.discountRate'">
                <el-input @change="changeContent($event, index)" style="width: 80px;" readonly
                          v-model="noteForm.contentList[index].discountRate"></el-input>
              </el-form-item>
              <el-form-item  label="开票金额:"
                            :prop="'contentList.' + index + '.invoiceAmount'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums*noteForm.contentList[index].unitPrice*noteForm.contentList[index].discountRate*0.01"></el-input>
              </el-form-item>
            </div>

            <div style="margin-left: 30px;" v-if="noteForm.contentList[index].unitPrice1">
              <el-form-item label="技术服务:" >
              </el-form-item>
              <el-form-item label="单价（元/㎡）:"
                            :prop="'contentList.' + index + '.unitPrice1'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;" readonly
                          v-model="noteForm.contentList[index].unitPrice1"></el-input>
              </el-form-item>
              <el-form-item  label="数量（个）:"
                            :prop="'contentList.' + index + '.nums1'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums1"></el-input>
              </el-form-item>
              <el-form-item  label="合计（元）:"
                             :prop="'contentList.' + index + '.total1'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums1*noteForm.contentList[index].unitPrice1"></el-input>
              </el-form-item>
              <!--              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="中标费率:"-->
              <!--                            :prop="'contentList.' + index + '.content'">-->
              <!--                <el-input @change="changeContent($event, index)" style="width: 80px;"-->
              <!--                          v-model="noteForm.contentList[index].bn"></el-input>-->
              <!--              </el-form-item>-->
              <el-form-item  label="开票金额:"
                            :prop="'contentList.' + index + '.invoiceAmount1'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums1*noteForm.contentList[index].unitPrice1"></el-input>
              </el-form-item>
            </div>
            <div style="margin-left: 30px;" v-if="noteForm.contentList[index].unitPrice2">
              <el-form-item label="消防检查:" >
              </el-form-item>
              <el-form-item  label="单价（元/㎡）:"
                            :prop="'contentList.' + index + '.unitPrice2'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;" readonly
                          v-model="noteForm.contentList[index].unitPrice2"></el-input>
              </el-form-item>
              <el-form-item  label="数量（个）:"
                            :prop="'contentList.' + index + '.nums2'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums2"></el-input>
              </el-form-item>
              <el-form-item  label="合计（元）:"
                             :prop="'contentList.' + index + '.total2'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums2*noteForm.contentList[index].unitPrice2"></el-input>
              </el-form-item>
              <!--              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="中标费率:"-->
              <!--                            :prop="'contentList.' + index + '.content'">-->
              <!--                <el-input @change="changeContent($event, index)" style="width: 80px;"-->
              <!--                          v-model="noteForm.contentList[index].bn"></el-input>-->
              <!--              </el-form-item>-->
              <el-form-item  label="开票金额:"
                            :prop="'contentList.' + index + '.invoiceAmount2'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums2*noteForm.contentList[index].unitPrice2"></el-input>
              </el-form-item>
            </div>
            <div style="margin-left: 30px;" v-if="noteForm.contentList[index].unitPrice3">
              <el-form-item label="过程监管:">
              </el-form-item>
              <el-form-item label="单价（元/㎡）:"
                            :prop="'contentList.' + index + '.unitPrice3'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;" readonly
                          v-model="noteForm.contentList[index].unitPrice3"></el-input>
              </el-form-item>
              <el-form-item  label="数量（个）:"
                            :prop="'contentList.' + index + '.nums3'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums3"></el-input>
              </el-form-item>
              <el-form-item  label="合计（元）:"
                             :prop="'contentList.' + index + '.total3'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums3*noteForm.contentList[index].unitPrice3"></el-input>
              </el-form-item>
              <!--              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="中标费率:"-->
              <!--                            :prop="'contentList.' + index + '.content'">-->
              <!--                <el-input @change="changeContent($event, index)" style="width: 80px;"-->
              <!--                          v-model="noteForm.contentList[index].bn"></el-input>-->
              <!--              </el-form-item>-->
              <el-form-item  label="开票金额:"
                            :prop="'contentList.' + index + '.invoiceAmount3'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums3*noteForm.contentList[index].unitPrice3"></el-input>
              </el-form-item>
            </div>
            <div v-if="noteForm.contentList[index].unitPrice4">
              <el-form-item label="评估报告监管服务:" label-width="140">
              </el-form-item>
              <el-form-item  label="单价（元/㎡）:"
                            :prop="'contentList.' + index + '.unitPrice4'" style="margin-right: 10px;" label-width="100">
                <el-input @change="changeContent($event, index)" style="width: 80px;" readonly
                          v-model="noteForm.contentList[index].unitPrice4"></el-input>
              </el-form-item>
              <el-form-item  label="数量（个）:"
                            :prop="'contentList.' + index + '.nums4'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums4"></el-input>
              </el-form-item>
              <el-form-item label="合计（元）:"
                            :prop="'contentList.' + index + '.total4'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums4*noteForm.contentList[index].unitPrice4"></el-input>
              </el-form-item>
              <!--              <el-form-item :rules="{ required: true, message: '内容不能为空', trigger: 'blur' }" label="中标费率:"-->
              <!--                            :prop="'contentList.' + index + '.content'">-->
              <!--                <el-input @change="changeContent($event, index)" style="width: 80px;"-->
              <!--                          v-model="noteForm.contentList[index].bn"></el-input>-->
              <!--              </el-form-item>-->
              <el-form-item  label="开票金额:"
                            :prop="'contentList.' + index + '.invoiceAmount4'">
                <el-input @change="changeContent($event, index)" style="width: 120px;" readonly
                          v-model="noteForm.contentList[index].nums4*noteForm.contentList[index].unitPrice4"></el-input>
              </el-form-item>

            </div>
          </el-card>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="passReview" :loading="saveShowLoading">通过</el-button>
        <el-button type="primary" @click="rejectReview" :loading="saveShowLoading">拒绝</el-button>
        <el-button @click.native="reviewDiaClose">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="审核"
        :visible.sync="dialogVisible"
        width="30%">
      <span style="font-size : large">确认提交审核？</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
<!--    <el-button type="primary" @click="dialogApply(1) ">拒 绝</el-button>-->
    <el-button type="primary" @click="dialogApply(2) ">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import util from "../../../util/date";
import { getButtonList } from '@/promissionRouter'
import {
  getInvoiceList,
  getInvoiceDetailList,
  getAreaDictionaryList,
  getCompanyContractList,
  getAllProjectList,
  addFinancialInvoice,
  getByNamespace,
  editFinancialInvoice,
  reviewFinancialApplyInvoice,
  submitFinancialApplyInvoice,
  getCurrentDrContract, getProjectApplicationByProjectId,
} from '@/api/api'

export default {
  components: { Toolbar },
  data () {
    var ruleBnValidator = function (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入内容'))
      } else {
        let reg =  /^\d+(\.\d{1,2})?$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入数字'))
        }
      }
    }
    return {
      totalNums:undefined,
      card: 'your-card-value',
      // checkedService:[],
      cities:[{value:'图纸审查',id:0},{value:'技术服务',id:1},{value:'消防检查',id:2},{value:'过程监管',id:3},{value:'评估报告监管服务',id:4},],
      dialogVisible: false,
      contDialog:false,//五项是否显示
      saveShowLoading:false,//保存加载
      noteRules: {
        invoiceTitle:[{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
        }],
        phone: [{ required: true, message: '该内容不能为空！', trigger: 'blur' },{
          validator:this.validatePhone,trigger: 'blur' }],//手机号
        email: [{ required: true, message: '该内容不能为空！', trigger: 'blur' }, {
          validator: this.validateToEmail, trigger: 'blur' }],//邮箱
        // constructionUnit:[{
        //
        // }],
        unitPrice: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        nums: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        total: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        bidRate: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        invoiceAmount: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        unitPrice1: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        nums1: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        total1: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        bidRate1: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        invoiceAmount1: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        unitPrice2: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        nums2: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        total2: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        bidRate2: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        invoiceAmount2: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        unitPrice3: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        nums3: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        total3: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        bidRate3: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        invoiceAmount3: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        unitPrice4: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        nums4: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        total4: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        bidRate4: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        invoiceAmount4: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        constructionUnit: [{
          required: true,
          message: '只能输入数字',
          trigger: 'blur',
          validator: ruleBnValidator
        }],



      },
      unNeed: {
        pic: false,
        content: false
      },
      noteForm: {
        changArea:undefined,
        invoiceTitle:undefined,
        invoiceFax:undefined,
        phone:undefined,
        email:undefined,
        contentList: [{drProjectId:undefined, constructionUnit:'', unitPrice: undefined, nums: undefined, total:undefined,discountRate:undefined,invoiceAmount:undefined,
          unitPrice1: undefined, nums1: undefined, total1: undefined ,discountRate1:100,invoiceAmount1:undefined,
          unitPrice2: undefined, nums2: undefined, total2: undefined ,discountRate2:100,invoiceAmount2:undefined,
          unitPrice3: undefined, nums3: undefined, total3: undefined ,discountRate3:100,invoiceAmount3:undefined,
          unitPrice4: undefined, nums4: undefined, total4: undefined ,discountRate4:100,invoiceAmount4:undefined,
          checkService:[]}]
      },
      provinceArr:[],//省份
      pickerData: [],
      utils:'',
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [15, 30, 45, 60],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: this.dataCount, //总条数
      },
      dataCount:undefined,
      //发票状态
      billToMatch: [
        { name: '未开票', value: 0 },
        { name: '开票失败', value: 1 },
        { name: '已开票', value: 2 },
        { name: '已作废', value: 3 },
      ],
      //项目状态
      objectToMatch: [
        { name: '未匹配', value: 0 },
        { name: '部分匹配', value: 1 },
        { name: '全部匹配', value: 2 }
      ],
      invoiceToMatch: [
        { name: '未匹配', value: 0 },
        { name: '部分匹配', value: 1 },
        { name: '全部匹配', value: 2 }
      ],
      applyStatusArr:[
        { name: '申请中', value: 0 },
        { name: '申请失败', value: 1 },
        { name: '申请成功', value: 2 },
        { name: '待申请', value: 9 }
      ],
      //筛选
      filters: {
        verificationStatus:'',//收款匹配状态
        invoiceName: '',//发票名称
        filingStartDate: '',//开票开始时间
        filingEndDate: '',//开票结束时间
        invoiceQuery: '',//发票名称查询
        invoiceStatus:'',//发票匹配状态
        applyStatus:'',//申请状态
        provinceCode:'',//省份
      },
      objectComplete: {},//列表接口数据
      objectDataList: [],//列表数组
      listLoading: false,
      addLoading: false,
      buttonList: [],//按钮事件列表
      needData: undefined,
      dialog: {
        //匹配项目是否弹出
        matchObjectDialog: {
          show: false
        },
        //匹配发票是否弹出
        matchInvoiceDialog: {
          show: false
        },
      },
      objectMatchList: [],//项目匹配
      InvoiceMatchList: [],//发票匹配
      needInvoiceData: {},//发票列表某一行
      needObjectData: {},//项目列表某一行
      addForm: {
        revenuePaymentsId: undefined,
        matchAmount: 0,
        financialReceivableCycleId: undefined,
      },
      paymentName: '',
      revenuePaymentData: undefined,
      openInvoice:false,
      invoiceDialog:false,
      invoiceDetailList:[],
      openInvoiceDialog:false,//申请开票弹窗是否打开
      companyData:[],
      sameCompanyId:false,
      companyDataOther:[],
      invoiceItemArray:[],
      editDialog:false,
      invoiceDetailObject:[],
      invoiceCompanyId:undefined,
      invoiceSelect:'',
      openReviewDialog:false,//审核弹窗
      companyListData:[],
      contractNo:undefined,
      areaCode:'',
      companyObj:undefined,
      invoiceMoneyAll:null,
    }
  },
  created () {
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
    this.init();
    this.getDataList()
  },
  methods: {
    getInvoiceMoneyAll(){
      this.invoiceMoneyAll = null
      this.invoiceItemArray = []
      if (this.noteForm && this.noteForm.contentList && Array.isArray(this.noteForm.contentList)&&this.noteForm.contentList.length > 1) {
        this.noteForm.contentList.forEach((item,index) => {
          const invoiceList = [
            this.isChecked('图纸审查',index) ? {
              discountRate: item.discountRate,//折扣
              drProjectId: item.drProjectId,//项目Id
              finishDate: '',//完成日期
              invoiceAmount: item.unitPrice * item.nums * item.discountRate * 0.01,//开票金额
              invoiceItemType: 248,//发票明细类型ID
              itemDetail: item.constructionUnit,//明细说明
              nums: item.nums,//数量
              receivableAmount: item.unitPrice * item.nums,//应收金额
              unitPrice: item.unitPrice,//单价
            } : undefined,
            this.isChecked('技术服务',index) ? {
              discountRate: item.discountRate1,//折扣
              drProjectId: item.drProjectId,//项目Id
              finishDate: '',//完成日期
              invoiceAmount: item.unitPrice1 * item.nums1,//开票金额
              invoiceItemType: 249,//发票明细类型ID
              itemDetail: item.constructionUnit,//明细说明
              nums: item.nums1,//数量
              receivableAmount: item.unitPrice1 * item.nums1,//应收金额
              unitPrice: item.unitPrice1,//单价
            } : undefined,
            this.isChecked('消防检查',index) ? {
              discountRate: item.discountRate2,//折扣
              drProjectId: item.drProjectId,//项目Id
              finishDate: '',//完成日期
              invoiceAmount: item.unitPrice2 * item.nums2,//开票金额
              invoiceItemType: 250,//发票明细类型ID
              itemDetail: item.constructionUnit,//明细说明
              nums: item.nums2,//数量
              receivableAmount: item.unitPrice2 * item.nums2,//应收金额
              unitPrice: item.unitPrice2,//单价
            } : undefined,
            this.isChecked('过程监管',index) ? {
              discountRate: item.discountRate3,//折扣
              drProjectId: item.drProjectId,//项目Id
              finishDate: '',//完成日期
              invoiceAmount: item.unitPrice3 * item.nums3,//开票金额
              invoiceItemType: 251,//发票明细类型ID
              itemDetail: item.constructionUnit,//明细说明
              nums: item.nums3,//数量
              receivableAmount: item.unitPrice3 * item.nums3,//应收金额
              unitPrice: item.unitPrice3,//单价
            } : undefined,
            this.isChecked('评估报告监管服务',index) ? {
              discountRate: item.discountRate4,//折扣
              drProjectId: item.drProjectId,//项目Id
              finishDate: '',//完成日期
              invoiceAmount: item.unitPrice4 * item.nums4,//开票金额
              invoiceItemType: 252,//发票明细类型ID
              itemDetail: item.constructionUnit,//明细说明
              nums: item.nums4,//数量
              receivableAmount: item.unitPrice4 * item.nums4,//应收金额
              unitPrice: item.unitPrice4,//单价
            } : undefined,
          ]
          const arr = invoiceList.filter(item => item !== null && item !== undefined && item !== '');
          this.invoiceItemArray.push(...arr)
        })
      } else {
        let arr = [
          this.isChecked('图纸审查',0) ? {
            discountRate: +this.noteForm.contentList[0].discountRate,//折扣
            drProjectId: +this.noteForm.contentList[0].drProjectId,//项目Id
            finishDate: '',//完成日期
            invoiceAmount: 0.01 * (+this.noteForm.contentList[0].discountRate) * (+this.noteForm.contentList[0].unitPrice) * (+this.noteForm.contentList[0].nums),//开票金额
            invoiceItemType: 248,//发票明细类型ID
            itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
            nums: +this.noteForm.contentList[0].nums,//数量
            receivableAmount: (+this.noteForm.contentList[0].unitPrice) * (+this.noteForm.contentList[0].nums),//应收金额
            unitPrice: +this.noteForm.contentList[0].unitPrice,//单价
          } : undefined,
          this.isChecked('技术服务',0) ? {
            discountRate: +this.noteForm.contentList[0].discountRate1,//折扣
            drProjectId: +this.noteForm.contentList[0].drProjectId,//项目Id
            finishDate: '',//完成日期
            invoiceAmount: (+this.noteForm.contentList[0].unitPrice1) * (+this.noteForm.contentList[0].nums1),//开票金额
            invoiceItemType: 249,//发票明细类型ID
            itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
            nums: +this.noteForm.contentList[0].nums1,//数量
            receivableAmount: (+this.noteForm.contentList[0].unitPrice1) * (+this.noteForm.contentList[0].nums1),//应收金额
            unitPrice: +this.noteForm.contentList[0].unitPrice1,//单价
          } : undefined, this.isChecked('消防检查',0) ? {
            discountRate: +this.noteForm.contentList[0].discountRate2,//折扣
            drProjectId: +this.noteForm.contentList[0].drProjectId,//项目Id
            finishDate: '',//完成日期
            invoiceAmount: (+this.noteForm.contentList[0].unitPrice2) * (+this.noteForm.contentList[0].nums2),//开票金额
            invoiceItemType: 250,//发票明细类型ID
            itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
            nums: +this.noteForm.contentList[0].nums2,//数量
            receivableAmount: +(+this.noteForm.contentList[0].unitPrice2) * (+this.noteForm.contentList[0].nums2),//应收金额
            unitPrice: +this.noteForm.contentList[0].unitPrice2,//单价
          } : undefined, this.isChecked('过程监管',0) ? {
            discountRate: +this.noteForm.contentList[0].discountRate3,//折扣
            drProjectId: +this.noteForm.contentList[0].drProjectId,//项目Id
            finishDate: '',//完成日期
            invoiceAmount: (+this.noteForm.contentList[0].unitPrice3) * (+this.noteForm.contentList[0].nums3),//开票金额
            invoiceItemType: 251,//发票明细类型ID
            itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
            nums: +this.noteForm.contentList[0].nums3,//数量
            receivableAmount: (+this.noteForm.contentList[0].unitPrice3) * (+this.noteForm.contentList[0].nums3),//应收金额
            unitPrice: +this.noteForm.contentList[0].unitPrice3,//单价
          } : undefined, this.isChecked('评估报告监管服务',0) ? {
            discountRate: +this.noteForm.contentList[0].discountRate4,//折扣
            drProjectId: +this.noteForm.contentList[0].drProjectId,//项目Id
            finishDate: '',//完成日期
            invoiceAmount: (+this.noteForm.contentList[0].unitPrice4) * (+this.noteForm.contentList[0].nums4),//开票金额
            invoiceItemType: 252,//发票明细类型ID
            itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
            nums: +this.noteForm.contentList[0].nums4,//数量
            receivableAmount: (+this.noteForm.contentList[0].unitPrice4) * (+this.noteForm.contentList[0].nums4),//应收金额
            unitPrice: +this.noteForm.contentList[0].unitPrice4,//单价
          } : undefined
        ]
        this.invoiceItemArray = arr.filter(item => item !== null && item !== undefined && item !== '');
      }
      console.log(this.invoiceItemArray,'list111111111111111111')
      const arr = this.invoiceItemArray.map(item=> item.invoiceAmount)
      let sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      console.log(sum,'arr')
      console.log(this.noteForm.contentList,'ddddddd')
      this.invoiceMoneyAll = sum
    },
    changeSelectArea(val){
      console.log(val,'changeVal')
      const obj = this.companyListData.find(item=>item.id === val)
      this.companyObj = obj
      this.contractNo = obj.contractNo
      this.areaCode = obj.districtCode
      this.getCompanyIds()
    },
    isChecked(city, index) {
      if (this.noteForm.contentList){
        return this.noteForm.contentList[index].checkService.includes(city);
      }
    },
    submitApplication (){
      if (!this.needData) {
        this.$message({
          message: '请选择一条数据！',
          type: 'error'
        })
        return
      }
      if (this.needData.applyStatus !== 9) {
        this.$message({
          message: '不符合提交审核条件！',
          type: 'error'
        })
        return
      }
      this.dialogVisible = true;
    },
    async dialogApply(value){
      let params = {
        applyStatus:value,
        id:this.needData.id,
      };
      const res = await submitFinancialApplyInvoice(params)
      if (res.data.success) {
        this.getListData()

        this.$message({
          message: res.data.message,
          type: 'success'
        })

        this.dialogVisible = false ;
        this.getListData()
      } else {
        this.$message({
          message: res.data.message,
          type: 'error'
        })
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    async changeSelect(index,event) {

      for (const [i, item] of this.noteForm.contentList.entries()) {
        if (item.drProjectId === event && i !== index) {
          this.$message({
            message: '不能重复',
            type: 'error'
          });
          break; // 当 if 条件成立时，使用 break 终止循环
        }
      }

        // 在这里处理选择改变时的逻辑
        console.log('选择改变了，新值为：', event,index);
        // 其他逻辑处理...
        const foundObject = this.companyData.find(obj => obj.id === event);
        console.log(foundObject,'cin')
        let params = {
          projectId:foundObject.id
        }
        const res = await getProjectApplicationByProjectId(params)
        const result = res.data.response
        console.log(result,'result11111')

        // this.invoiceSelect = foundObject
        this.noteForm.contentList[index].unitPrice  = foundObject.projectUnitPrice
        this.noteForm.contentList[index].nums  = foundObject.projectArea
        this.noteForm.contentList[index].unitPrice1  = foundObject.techServicePrice
        this.noteForm.contentList[index].unitPrice2  = foundObject.acceptancePrice
        this.noteForm.contentList[index].unitPrice3  = foundObject.processSupervisionPrice
        this.noteForm.contentList[index].unitPrice4  = foundObject.estimateServicePrice
        this.noteForm.contentList[index].constructionUnit  = result.contractor
        this.contDialog = true

    },
    validateToEmail (rule, value, callback) {
      // 自定义验证函数
      const email = this.invoiceApply.email;
      if (email){
        this.$refs.noteRef.clearValidate('phone');
        if (this.validateEmail(email)){
          callback()
        }else {
          callback(new Error('邮箱格式错误，请重新输入！'))
        }
      }


    },
    validatePhone(rule, value, callback) {
      // 自定义验证函数
      const phone = this.invoiceApply.phone;
      const email = this.invoiceApply.email;
      if (phone) {
        this.$refs.noteRef.clearValidate('email');
        if (this.validateMobileNumber(phone)) {
          callback()
        } else {
          callback(new Error('手机号格式错误，请重新输入！'))
        }
      }
    },
    //保存申请开票
    saveNote () {
      this.$refs.noteRef.validate(async (valid) => {
        if (valid) {
          this.invoiceItemArray = []
          // const foundObject = this.companyData.find(obj => obj.id === this.companyObj.id);
          if (this.noteForm && this.noteForm.contentList && Array.isArray(this.noteForm.contentList)&&this.noteForm.contentList.length > 1) {
            this.noteForm.contentList.forEach((item,index) => {
              const invoiceList = [
                this.isChecked('图纸审查',index) ? {
                  discountRate: item.discountRate,//折扣
                  drProjectId: item.drProjectId,//项目Id
                  finishDate: '',//完成日期
                  invoiceAmount: item.unitPrice * item.nums * item.discountRate * 0.01,//开票金额
                  invoiceItemType: 248,//发票明细类型ID
                  itemDetail: item.constructionUnit,//明细说明
                  nums: item.nums,//数量
                  receivableAmount: item.unitPrice * item.nums,//应收金额
                  unitPrice: item.unitPrice,//单价
                } : undefined,
                this.isChecked('技术服务',index) ? {
                  discountRate: item.discountRate1,//折扣
                  drProjectId: item.drProjectId,//项目Id
                  finishDate: '',//完成日期
                  invoiceAmount: item.unitPrice1 * item.nums1,//开票金额
                  invoiceItemType: 249,//发票明细类型ID
                  itemDetail: item.constructionUnit,//明细说明
                  nums: item.nums1,//数量
                  receivableAmount: item.unitPrice1 * item.nums1,//应收金额
                  unitPrice: item.unitPrice1,//单价
                } : undefined,
                this.isChecked('消防检查',index) ? {
                  discountRate: item.discountRate2,//折扣
                  drProjectId: item.drProjectId,//项目Id
                  finishDate: '',//完成日期
                  invoiceAmount: item.unitPrice2 * item.nums2,//开票金额
                  invoiceItemType: 250,//发票明细类型ID
                  itemDetail: item.constructionUnit,//明细说明
                  nums: item.nums2,//数量
                  receivableAmount: item.unitPrice2 * item.nums2,//应收金额
                  unitPrice: item.unitPrice2,//单价
                } : undefined,
                this.isChecked('过程监管',index) ? {
                  discountRate: item.discountRate3,//折扣
                  drProjectId: item.drProjectId,//项目Id
                  finishDate: '',//完成日期
                  invoiceAmount: item.unitPrice3 * item.nums3,//开票金额
                  invoiceItemType: 251,//发票明细类型ID
                  itemDetail: item.constructionUnit,//明细说明
                  nums: item.nums3,//数量
                  receivableAmount: item.unitPrice3 * item.nums3,//应收金额
                  unitPrice: item.unitPrice3,//单价
                } : undefined,
                this.isChecked('评估报告监管服务',index) ? {
                  discountRate: item.discountRate4,//折扣
                  drProjectId: item.drProjectId,//项目Id
                  finishDate: '',//完成日期
                  invoiceAmount: item.unitPrice4 * item.nums4,//开票金额
                  invoiceItemType: 252,//发票明细类型ID
                  itemDetail: item.constructionUnit,//明细说明
                  nums: item.nums4,//数量
                  receivableAmount: item.unitPrice4 * item.nums4,//应收金额
                  unitPrice: item.unitPrice4,//单价
                } : undefined,
              ]
              const arr = invoiceList.filter(item => item !== null && item !== undefined && item !== '');
              this.invoiceItemArray.push(...arr)
            })
          } else {
            this.invoiceItemArray = []
            let arr = [
              this.isChecked('图纸审查',0) ? {
                discountRate: +this.noteForm.contentList[0].discountRate,//折扣
                drProjectId: +this.noteForm.contentList[0].drProjectId,//项目Id
                finishDate: '',//完成日期
                invoiceAmount: 0.01 * (+this.noteForm.contentList[0].discountRate) * (+this.noteForm.contentList[0].unitPrice) * (+this.noteForm.contentList[0].nums),//开票金额
                invoiceItemType: 248,//发票明细类型ID
                itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
                nums: +this.noteForm.contentList[0].nums,//数量
                receivableAmount: (+this.noteForm.contentList[0].unitPrice) * (+this.noteForm.contentList[0].nums),//应收金额
                unitPrice: +this.noteForm.contentList[0].unitPrice,//单价
              } : undefined,
              this.isChecked('技术服务',0) ? {
                discountRate: +this.noteForm.contentList[0].discountRate1,//折扣
                drProjectId: +this.noteForm.contentList[0].drProjectId,//项目Id
                finishDate: '',//完成日期
                invoiceAmount: (+this.noteForm.contentList[0].unitPrice1) * (+this.noteForm.contentList[0].nums1),//开票金额
                invoiceItemType: 249,//发票明细类型ID
                itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
                nums: +this.noteForm.contentList[0].nums1,//数量
                receivableAmount: (+this.noteForm.contentList[0].unitPrice1) * (+this.noteForm.contentList[0].nums1),//应收金额
                unitPrice: +this.noteForm.contentList[0].unitPrice1,//单价
              } : undefined, this.isChecked('消防检查',0) ? {
                discountRate: +this.noteForm.contentList[0].discountRate2,//折扣
                drProjectId: +this.noteForm.contentList[0].drProjectId,//项目Id
                finishDate: '',//完成日期
                invoiceAmount: (+this.noteForm.contentList[0].unitPrice2) * (+this.noteForm.contentList[0].nums2),//开票金额
                invoiceItemType: 250,//发票明细类型ID
                itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
                nums: +this.noteForm.contentList[0].nums2,//数量
                receivableAmount: +(+this.noteForm.contentList[0].unitPrice2) * (+this.noteForm.contentList[0].nums2),//应收金额
                unitPrice: +this.noteForm.contentList[0].unitPrice2,//单价
              } : undefined, this.isChecked('过程监管',0) ? {
                discountRate: +this.noteForm.contentList[0].discountRate3,//折扣
                drProjectId: +this.noteForm.contentList[0].drProjectId,//项目Id
                finishDate: '',//完成日期
                invoiceAmount: (+this.noteForm.contentList[0].unitPrice3) * (+this.noteForm.contentList[0].nums3),//开票金额
                invoiceItemType: 251,//发票明细类型ID
                itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
                nums: +this.noteForm.contentList[0].nums3,//数量
                receivableAmount: (+this.noteForm.contentList[0].unitPrice3) * (+this.noteForm.contentList[0].nums3),//应收金额
                unitPrice: +this.noteForm.contentList[0].unitPrice3,//单价
              } : undefined, this.isChecked('评估报告监管服务',0) ? {
                discountRate: +this.noteForm.contentList[0].discountRate4,//折扣
                drProjectId: +this.noteForm.contentList[0].drProjectId,//项目Id
                finishDate: '',//完成日期
                invoiceAmount: (+this.noteForm.contentList[0].unitPrice4) * (+this.noteForm.contentList[0].nums4),//开票金额
                invoiceItemType: 252,//发票明细类型ID
                itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
                nums: +this.noteForm.contentList[0].nums4,//数量
                receivableAmount: (+this.noteForm.contentList[0].unitPrice4) * (+this.noteForm.contentList[0].nums4),//应收金额
                unitPrice: +this.noteForm.contentList[0].unitPrice4,//单价
              } : undefined
            ]
            this.invoiceItemArray = arr.filter(item => item !== null && item !== undefined && item !== '');
          }

          // console.log(foundObject, 'fun')
          let params = {
            companyId: this.companyObj.companyId,//
            externalPersonEmail: this.noteForm.email,//收件人邮箱
            externalPersonPhone: this.noteForm.phone,//收件人手机号
            invoiceFax: this.noteForm.invoiceFax,//税号
            invoiceTitle: this.noteForm.invoiceTitle,//开票名称
            invoiceItemList: this.invoiceItemArray,//发票明细
            // id: this.needData.id,//发票id
          }
          let res = await addFinancialInvoice(params)
          if (res.data.success) {
            this.invoiceColse()
            this.getListData()
            this.invoiceItemArray = []
            this.$message({
              message: res.data.message,
              type: 'success'
            })
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            })
          }
          console.log(this.noteForm.contentList)
          this.sameCompanyId = false;//不要忘记
        }
      })
    },
      addNoteDiaClose(){
        this.openInvoiceDialog = false;
        this.invoiceColse()

      },
      //审核取消
      reviewDiaClose(){
        this.openReviewDialog = false
        this.invoiceColse()

      },
      invoiceColse(){
        this.contractNo = undefined
        this.contDialog = false;
        this.openInvoiceDialog = false;
        this.contDialog = false;
        this.invoiceItemArray = []
        this.noteForm = {
          // invoiceTitle:undefined,
          //     invoiceFax:undefined,
          //     phone:undefined,
          //     email:undefined,
              contentList: [{drProjectId:undefined, constructionUnit:'', unitPrice: undefined, nums: undefined, total: undefined ,discountRate:100,invoiceAmount:undefined,
                unitPrice1: undefined, nums1: 1, total1: undefined ,discountRate1:100,invoiceAmount1:undefined,
                unitPrice2: undefined, nums2: 1, total2: undefined ,discountRate2:100,invoiceAmount2:undefined,
                unitPrice3: undefined, nums3: 1, total3: undefined ,discountRate3:100,invoiceAmount3:undefined,
                unitPrice4: undefined, nums4: 1, total4: undefined ,discountRate4:100,invoiceAmount4:undefined,
                checkService: []}]
        }

    },
    async namespace(){
      let params = {
        namespace:'BizService.Finance.InvoiceItemType'
      }
      const res = await getByNamespace(params)
      if (res) {
        console.log(res,'nameSpace')

      } else {
        this.$message({
          message: res.data.message,
          type: 'error'
        })
      }
    },
    // 输入时判断content中bn是否有重复
    changeContent (e, index) {
      // this.totalArray()
      console.log(e,index)
    },
    // 删除内容框card
    delContentInput (index) {
      this.noteForm.contentList.splice(index,1)
    },
    // 新增内容框
   async addContentInput () {
      this.contDialog = false
      if (this.companyData&&this.companyData.length>1){
          this.noteForm.contentList.push({drProjectId:undefined, constructionUnit:'', unitPrice: undefined, nums: undefined, total: undefined ,discountRate:100,invoiceAmount:undefined,
            unitPrice1: undefined, nums1: 1, total1: undefined ,discountRate1:100,invoiceAmount1:undefined,
            unitPrice2: undefined, nums2: 1, total2: undefined ,discountRate2:100,invoiceAmount2:undefined,
            unitPrice3: undefined, nums3: 1, total3: undefined ,discountRate3:100,invoiceAmount3:undefined,
            unitPrice4: undefined, nums4: 1, total4: undefined ,discountRate4:100,invoiceAmount4:undefined,
            checkService:[],})
        }else {
          this.$message({
            message:'该公司已无无其他项目，无法添加新项目!',
            type:'error'
          })
        }
    },
    async addContentEdit(){
      const foundObject = this.companyData.find(obj => obj.id === this.invoiceCompanyId);
      const foundObject1 = this.companyData.filter(obj => obj.companyId === foundObject.companyId);
      console.log(foundObject1,'111')
      if (foundObject1.length>1){
        this.sameCompanyId = true;
          this.companyDataOther = foundObject1
          this.noteForm.contentList.push({drProjectId:undefined, constructionUnit:'', unitPrice: undefined, nums: undefined, total: undefined ,discountRate:undefined,invoiceAmount:undefined,
            unitPrice1: undefined, nums1: undefined, total1: undefined ,discountRate1:100,invoiceAmount1:undefined,
            unitPrice2: undefined, nums2: undefined, total2: undefined ,discountRate2:100,invoiceAmount2:undefined,
            unitPrice3: undefined, nums3: undefined, total3: undefined ,discountRate3:100,invoiceAmount3:undefined,
            unitPrice4: undefined, nums4: undefined, total4: undefined ,discountRate4:100,invoiceAmount4:undefined,
            checkService:[]})
        }else {
          this.$message({
            message:'该公司已无无其他项目，无法添加新项目!',
            type:'error'
          })
        }
    },
    //申请开票
    applyOpenInvoice(){
      // if (!this.needData) {
      //   this.$message({
      //     message: '请选择一条数据',
      //     type: 'error'
      //   })
      //   return
      // }

      this.invoiceColse()
      this.invoiceMoneyAll = undefined
      this.companyData = undefined
      // this.getCompanyIds()
      // this.checkedService = []

      this.openInvoiceDialog = true;
    },
    //发票详情
    invoiceDetails(val){
      this.invoiceDetailList = []
      this.getInvoiceList(val.id)
      console.log(this.invoiceDetailList,'listkkkkk')
      this.noteForm = {
        // changArea:this.needData.contractName,
        invoiceTitle:this.needData.invoiceTitle,
        invoiceFax:this.needData.invoiceFax,
        phone:this.needData.externalPersonPhone,
        email:this.needData.externalPersonEmail,
        contentList: this.invoiceDetailList
      }
      this.invoiceDialog = true;

    },
    //获取当前登录用户的有效合同
    async getContract(){
      const res = await getCompanyContractList()
      if (res) {
        const data = res.data
        // this.companyData = data
        this.companyListData = data.response
        console.log(this.companyListData,'data')


      } else {
        this.$message({
          message: res.data.message,
          type: 'error'
        })
      }
    },
    //获取列表数据
    getDataList () {
      let params = {
        // invoiceIds: [],//发票ID组（逗号隔开）
        applyStatus:this.filters.applyStatus,//申请状态
        // verificationStatus: this.filters.verificationStatus,//收款匹配状态
        // invoiceStatus: this.filters.invoiceStatus,//发票匹配状态
        keywords: this.filters.invoiceQuery,//关键字查询
        openEndDate: this.filters.filingEndDate,//开票结束时间
        openStartDate: this.filters.filingStartDate,//开票开始时间
        provinceCode:this.filters.provinceCode,//省份
        pageIndex: 1,//页码
        pageSize: 15,//每页条数
      }
      getInvoiceList(params).then(res => {
        let result = res.data;
        if (result.success) {
          const response = result.response
          this.dataCount = response.dataCount
          const data = response.data
          this.objectDataList = data
        }
      })
      // this.getCompanyIds()
      this.namespace()
      this.getContract()
    },
    //获取合同列表
    async getCompanyIds(){
      let params = {
        areaCode:this.areaCode,
        pageIndex:1,
        pageSize:200,
      }
      let res = await getAllProjectList(params)
      let result = res.data
      console.log(result,'companyIds')
      let data = result.response.data
      this.companyData = data
    },
    // 查询
    getListData () {
      this.pages.pageIndex = 1
      this.getDataList();
    },
    async getInvoiceList(id){
      this.invoiceDetailList =[]
      let params = {
        invoiceId:id,
      }

      try {
        const res = await getInvoiceDetailList(params)
        let result = res.data
        if (result.status === 200) {
          let response = result.response
          console.log(response, 'response')
          this.invoiceCompanyId = response[0].drProjectId
          const originalArray = response
          // 使用 reduce 方法对数组进行处理
          const groupedArray = originalArray.reduce((acc, obj) => {
            const key = obj.drProjectId;
            if (!acc[key]) {
              acc[key] = []; // 如果键不存在，则初始化一个空数组
            }
            acc[key].push(obj); // 将具有相同 drProjectId 的对象推入对应的数组中
            return acc;
          }, {});
          // 将结果转换为数组形式
          const resultArray = Object.values(groupedArray);
          // this.invoiceDetailDia = resultArray
          console.log(resultArray, 'resultArray');
          resultArray.forEach(item => {
            const a = item.find(item => item.invoiceItemType === 248)
            const b = item.find(item => item.invoiceItemType === 249)
            const c = item.find(item => item.invoiceItemType === 250)
            const d = item.find(item => item.invoiceItemType === 251)
            const e = item.find(item => item.invoiceItemType === 252)
            const arr = [
              {
                drProjectId: item[0].projectName,
                constructionUnit: item[0].itemDetail,
                unitPrice: a ? a.unitPrice : undefined,
                nums: a ? a.nums : undefined,
                total: undefined,
                discountRate: a ? a.discountRate : undefined,
                invoiceAmount: undefined,
                unitPrice1: b ? b.unitPrice : undefined,
                nums1: b ? b.nums : undefined,
                total1: undefined,
                discountRate1: 100,
                invoiceAmount1: undefined,
                unitPrice2: c ? c.unitPrice : undefined,
                nums2: c ? c.nums : undefined,
                total2: undefined,
                discountRate2: 100,
                invoiceAmount2: undefined,
                unitPrice3: d ? d.unitPrice : undefined,
                nums3: d ? d.nums : undefined,
                total3: undefined,
                discountRate3: 100,
                invoiceAmount3: undefined,
                unitPrice4: e ? e.unitPrice : undefined,
                nums4: e ? e.nums : undefined,
                total4: undefined,
                discountRate4: 100,
                invoiceAmount4: undefined,
              }
            ]
            this.invoiceDetailList.push(...arr)
          })
        }
        console.log(result, 'result')
      }catch (error){
        console.log('Error occurred:',error)
      }
    },
    //审核通过
    passReview(){
      this.reviewPost(2)
    },
    //审核取消
    rejectReview(){
      this.reviewPost(1)
    },
    //建设单位
    async constructionUnits(id){
      const res = getProjectApplicationByProjectId(id)
      if (res){
        console.log(res,'resdddd')
      }
    },
    //审核
    async review(){
      if (!this.needData) {
        this.$message({
          message: '请选择一条数据',
          type: 'error'
        })
        return
      }
      if (this.needData.applyStatus !== 0) {
        this.$message({
          message: '不符合审核条件！',
          type: 'error'
        })
        return
      }
      // if (this.needData.invoiceStatus===9&&this.needData.invoiceStatus===3&&this.needData.invoiceStatus===2&&this.needData.invoiceStatus===1) {
      //   this.$message({
      //     message: '已审核',
      //     type: 'error'
      //   })
      //   return
      // }
      await  this.getInvoiceList(this.needData.id)
      console.log(this.invoiceDetailList,'detailList')
      this.noteForm = {
        // changArea:this.needData.contractName,
        invoiceTitle:this.needData.invoiceTitle,
        invoiceFax:this.needData.invoiceFax,
        phone:this.needData.externalPersonPhone,
        email:this.needData.externalPersonEmail,
        contentList: this.invoiceDetailList
      }
      this.openReviewDialog = true;
    },
    //审核post
    async reviewPost(value){
      let params = {
        applyStatus:value,//申请状态 0:申请中 1：拒绝 2：同意
        id:this.needData.id
      }
      const res = await reviewFinancialApplyInvoice(params)
      if (res.data.success) {
        this.reviewDiaClose()
        this.getListData()
        this.$message({
          message: '已审核',
          type: 'success'
        })
      } else {
        this.$message({
          message: res.data.message,
          type: 'error'
        })
      }
    },
    //编辑
    async handleEdit(){
      if (!this.needData) {
        this.$message({
          message: '请选择一条数据',
          type: 'error'
        })
        return
      }
      await  this.getInvoiceList(this.needData.id)
      console.log(this.invoiceDetailList,'detailList')
      this.noteForm = {
        // changArea:this.needData.contractName,
        invoiceTitle:this.needData.invoiceTitle,
        invoiceFax:this.needData.invoiceFax,
        phone:this.needData.externalPersonPhone,
        email:this.needData.externalPersonEmail,
        contentList: this.invoiceDetailList
      }
       this.openInvoiceDialog = true;

      this.editDialog = true;
    },
    isNumber(value) {
        return typeof value === 'number';
    },

//保存编辑
    async saveEdit(){
      this.$refs.noteRef.validate(async (valid) => {
        if (valid) {
          console.log(this.noteForm.contentList[0].drProjectId, this.invoiceCompanyId, '3333')
          const foundObject = this.companyData.find(obj => obj.id === (this.isNumber(this.noteForm.contentList[0].drProjectId) ? this.noteForm.contentList[0].drProjectId : this.invoiceCompanyId));
          console.log(foundObject, 'dddddddsfsfdsd')
          if (this.noteForm && this.noteForm.contentList && Array.isArray(this.noteForm.contentList)&&this.noteForm.contentList.length > 1) {
            this.noteForm.contentList.forEach(item => {
              const invoiceList = [
                {
                  discountRate: +item.discountRate,//折扣
                  drProjectId: this.isNumber(item.drProjectId) ? +item.drProjectId : this.invoiceCompanyId,//项目Id
                  finishDate: '',//完成日期
                  invoiceAmount: +item.invoiceAmount,//开票金额
                  invoiceItemType: 248,//发票明细类型ID
                  itemDetail: item.constructionUnit,//明细说明
                  nums: +item.nums,//数量
                  receivableAmount: +item.unitPrice * (+item.nums),//应收金额
                  unitPrice: +item.unitPrice,//单价
                }, {
                  discountRate: +item.discountRate1,//折扣
                  drProjectId: this.isNumber(item.drProjectId) ? +item.drProjectId : this.invoiceCompanyId,//项目Id
                  finishDate: '',//完成日期
                  invoiceAmount: +item.invoiceAmount1,//开票金额
                  invoiceItemType: 249,//发票明细类型ID
                  itemDetail: item.constructionUnit,//明细说明
                  nums: +item.nums1,//数量
                  receivableAmount: +item.unitPrice1 * (+item.nums1),//应收金额
                  unitPrice: +item.unitPrice1,//单价
                }, {
                  discountRate: +item.discountRate2,//折扣
                  drProjectId: this.isNumber(item.drProjectId) ? +item.drProjectId : this.invoiceCompanyId,//项目Id
                  finishDate: '',//完成日期
                  invoiceAmount: +item.invoiceAmount2,//开票金额
                  invoiceItemType: 250,//发票明细类型ID
                  itemDetail: item.constructionUnit,//明细说明
                  nums: +item.nums2,//数量
                  receivableAmount: +item.unitPrice2 * (+item.nums2),//应收金额
                  unitPrice: +item.unitPrice2,//单价
                }, {
                  discountRate: +item.discountRate3,//折扣
                  drProjectId: this.isNumber(item.drProjectId) ? +item.drProjectId : this.invoiceCompanyId,//项目Id
                  finishDate: '',//完成日期
                  invoiceAmount: +item.invoiceAmount3,//开票金额
                  invoiceItemType: 251,//发票明细类型ID
                  itemDetail: item.constructionUnit,//明细说明
                  nums: +item.nums3,//数量
                  receivableAmount: +item.unitPrice3 * (+item.nums3),//应收金额
                  unitPrice: +item.unitPrice3,//单价
                }, {
                  discountRate: +item.discountRate4,//折扣
                  drProjectId: this.isNumber(item.drProjectId) ? +item.drProjectId : this.invoiceCompanyId,//项目Id
                  finishDate: '',//完成日期
                  invoiceAmount: +item.invoiceAmount4,//开票金额
                  invoiceItemType: 252,//发票明细类型ID
                  itemDetail: item.constructionUnit,//明细说明
                  nums: +item.nums4,//数量
                  receivableAmount: +item.unitPrice4 * (+item.nums4),//应收金额
                  unitPrice: +item.unitPrice4,//单价
                }
              ]
              this.invoiceItemArray.push(...invoiceList)
            })
          } else {
            this.invoiceItemArray = [
              {
                discountRate: +this.noteForm.contentList[0].discountRate,//折扣
                drProjectId: this.isNumber(this.noteForm.contentList[0].drProjectId) ? +this.noteForm.contentList[0].drProjectId : this.invoiceCompanyId,//项目Id
                finishDate: '',//完成日期
                invoiceAmount: +this.noteForm.contentList[0].invoiceAmount,//开票金额
                invoiceItemType: 248,//发票明细类型ID
                itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
                nums: +this.noteForm.contentList[0].nums,//数量
                receivableAmount: +this.noteForm.contentList[0].nums * (+this.noteForm.contentList[0].unitPrice),//应收金额
                unitPrice: +this.noteForm.contentList[0].unitPrice,//单价
              }, {
                discountRate: +this.noteForm.contentList[0].discountRate1,//折扣
                drProjectId: this.isNumber(this.noteForm.contentList[0].drProjectId) ? +this.noteForm.contentList[0].drProjectId : this.invoiceCompanyId,//项目Id
                finishDate: '',//完成日期
                invoiceAmount: +this.noteForm.contentList[0].invoiceAmount1,//开票金额
                invoiceItemType: 249,//发票明细类型ID
                itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
                nums: +this.noteForm.contentList[0].nums1,//数量
                receivableAmount: +this.noteForm.contentList[0].nums1 * (+this.noteForm.contentList[0].unitPrice1),//应收金额
                unitPrice: +this.noteForm.contentList[0].unitPrice1,//单价
              }, {
                discountRate: +this.noteForm.contentList[0].discountRate2,//折扣
                drProjectId: this.isNumber(this.noteForm.contentList[0].drProjectId) ? +this.noteForm.contentList[0].drProjectId : this.invoiceCompanyId,//项目Id
                finishDate: '',//完成日期
                invoiceAmount: +this.noteForm.contentList[0].invoiceAmount2,//开票金额
                invoiceItemType: 250,//发票明细类型ID
                itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
                nums: +this.noteForm.contentList[0].nums2,//数量
                receivableAmount: +this.noteForm.contentList[0].unitPrice2 * (+this.noteForm.contentList[0].nums2),//应收金额
                unitPrice: +this.noteForm.contentList[0].unitPrice2,//单价
              }, {
                discountRate: +this.noteForm.contentList[0].discountRate3,//折扣
                drProjectId: this.isNumber(this.noteForm.contentList[0].drProjectId) ? +this.noteForm.contentList[0].drProjectId : this.invoiceCompanyId,//项目Id
                finishDate: '',//完成日期
                invoiceAmount: this.noteForm.contentList[0].invoiceAmount3,//开票金额
                invoiceItemType: 251,//发票明细类型ID
                itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
                nums: +this.noteForm.contentList[0].nums3,//数量
                receivableAmount: +this.noteForm.contentList[0].nums3 * (+this.noteForm.contentList[0].unitPrice3),//应收金额
                unitPrice: +this.noteForm.contentList[0].unitPrice3,//单价
              }, {
                discountRate: +this.noteForm.contentList[0].discountRate4,//折扣
                drProjectId: this.isNumber(this.noteForm.contentList[0].drProjectId) ? +this.noteForm.contentList[0].drProjectId : this.invoiceCompanyId,//项目Id
                finishDate: '',//完成日期
                invoiceAmount: +this.noteForm.contentList[0].invoiceAmount4,//开票金额
                invoiceItemType: 252,//发票明细类型ID
                itemDetail: this.noteForm.contentList[0].constructionUnit,//明细说明
                nums: +this.noteForm.contentList[0].nums4,//数量
                receivableAmount: +this.noteForm.contentList[0].nums4 * (+this.noteForm.contentList[0].unitPrice4),//应收金额
                unitPrice: +this.noteForm.contentList[0].unitPrice4,//单价
              }
            ]
          }

          console.log(foundObject, 'fun')
          let params = {
            companyId: foundObject.companyId,//
            externalPersonEmail: this.noteForm.email,//收件人邮箱
            externalPersonPhone: this.noteForm.phone,//收件人手机号
            invoiceFax: this.noteForm.invoiceFax,//税号
            invoiceTitle: this.noteForm.invoiceTitle,//开票名称
            invoiceItemList: this.invoiceItemArray,//发票明细
            id: this.needData.id
          }
          let res = await editFinancialInvoice(params)
          if (res.data.success) {
            this.invoiceColse()
            this.getListData()
            this.$message({
              message: res.data.message,
              type: 'success'
            })
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            })
          }
          console.log(this.noteForm.contentList)
          this.sameCompanyId = false;//不要忘记
        }
      })
    },
    //按钮事件
    callFunction (item) {
      this[item.func].apply(this, item);
    },
    // 点击table某一行
    currentChange (val) {
     if (val){
       this.needData = val
       console.log(val,'val')
     }

    },
    handleSelect (val) {
      this.addForm.revenuePaymentsId = val.id;
      console.log(val, 'val');
    },
    viewsEntyTemplate(){
      this.openInvoice = true;
    },

    // 日期选择
    changePicker (data) {
      if (data !== null) {
        this.filters.filingStartDate = data[0];
        this.filters.filingEndDate = data[1];
      } else {
        this.filters.filingStartDate = undefined;
        this.filters.filingEndDate = undefined;
      }
    },
    // 表格数据分页条数累计
    indexMethod (index) {
      let currentPage = this.pages.pageIndex
      let pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
    rowClass ({ columnIndex }) {
      return 'padding:8px!important;'
    },
    // 分页
    handleCurrentChange (val) {
      console.log(val,'fenye')
      this.pages.pageIndex = val;
      this.getDataList();
    },
    handleSizeChange (val) {
      console.log(val,'每页条数')
      this.pages.pageSize = val;
      this.getDataList();
    },
    async init () {
      //获取所有省份
      const initResult = await Promise.all([
        getAreaDictionaryList({ parentId: 1 }),
      ])
      const [provinceRes] = initResult;
      if (provinceRes.data.success) {
        this.provinceArr = provinceRes.data.response.reverse();
      }
      this.getDataList()
    }
  }
}
</script>

<style scoped>
  .dialog-divider {
    border-top: 1px solid #ccc; /* 横线样式 */
    margin: 20px 0; /* 调整横线与内容之间的间距 */
  }
</style>
